$(document).ready(function() {
  // tab

  $('.btnTabArea a').click(function() {
    $('.btnTabArea a').removeClass('selected');
    $(this).addClass('selected');

    var action = $(this).attr('data-panel');
    if (action == 'listview') {
      $('#ListView').show();
      $('#CardView').hide();
    } else {
      $('#ListView').hide();
      $('#CardView').show();
    }
  });

  //color line
  $('.nislo-datatable tbody.color-line td').hover(
    function() {
      $(this)
        .parent()
        .addClass('hover');
    },
    function() {
      $(this)
        .parent()
        .removeClass('hover');
    }
  );

  $('.nislo-datatable tbody.color-line td').click(function() {
    $('.nislo-datatable tbody.color-line tr').removeClass('selected');
    $(this)
      .parent()
      .addClass('selected');

    $('.nislo-datatable tbody.color-line td input').prop('checked', false);
    $(this)
      .parent()
      .find('td input')
      .prop('checked', true);
  });

  //feedback tooltip
  $('.nislo-datatable tbody td.fieldFeed').hover(
    function() {
      $(this)
        .find('div.tooltipFeedback')
        .show();
    },
    function() {
      $(this)
        .find('div.tooltipFeedback')
        .hide();
    }
  );

  // sort demo
  $('.nislo-datatable thead tr.sortable th').click(function() {
    $('.nislo-datatable thead tr.sortable th')
      .not($(this))
      .removeClass('sort sort1 sort2');

    var sorter = $(this).find('i').length;

    if (sorter) {
      if (!$(this).hasClass('sort')) {
        $(this).addClass('sort sort1');
        return false;
      }

      if ($(this).hasClass('sort2')) {
        $(this)
          .addClass('sort1')
          .removeClass('sort2');
        return false;
      }

      if ($(this).hasClass('sort1')) {
        $(this)
          .removeClass('sort1')
          .addClass('sort2');
        return false;
      }
    }
  });

  // Custom Tab
  $('.nislo-customTabNav a').click(function() {
    console.log('click');

    var tabNavLink = $(this).attr('data-nav');

    console.log(tabNavLink);

    $('.nislo-customTabContainer .nislo-customTabContent').hide();
    $(
      '.nislo-customTabContainer .nislo-customTabContent[data-container=' +
        tabNavLink +
        ']'
    ).show();

    //console.log(contentContainer.find("div.nislo-customTabContent[data-container="+tabNavLink+"]"));
  });

  //projectsNavContainer Slide
  $('#projectsNavButtons a').click(function() {
    var rel = $(this).attr('rel');
    var container = $('#projectsNavContainer ul');
    var pos = container.css('margin-left').replace('px', '');

    var cw = container.width();

    console.log(Math.abs(pos) + ' | ' + cw + ' | ' + pos);

    if (rel == 'next' && Math.abs(pos) <= cw) {
      console.log(pos);
      container.animate({ 'margin-left': '-=250px' }, 350);
    } else if (rel == 'prev' && pos < 0) {
      console.log(pos);
      container.animate({ 'margin-left': '+=250px' }, 350);
    }
  });

  // add supervisor own
  fieldSupervisorSort(); // sort supervisor badge
  $('.btnAddRemove').click(function() {
    //var nameletter = $(this).attr("data-name-letter");
    var added = $(this).hasClass('added');
    //console.log(added);
    if (added) {
      // çıkar
      $(this)
        .parent()
        .find('div.badge-supervisor.own')
        .addClass('d-none');
      $(this).removeClass('added');
    } else {
      // ekle
      //console.log("ekle");
      $(this)
        .parent()
        .find('div.badge-supervisor.own')
        .removeClass('d-none');
      $(this).addClass('added');
    }
    fieldSupervisorSort($(this).parent());
  });

  // add-orders custom form script

  $('#supervisor').focus(function() {
    var rel = $(this).attr('rel');
    if (rel < 1) {
      openModal('_modal-workload.html');
      $(this).attr('rel', 1);
    }
  });
  $('.modal-container').on('hidden.bs.modal', function() {
    var relInput = $('#supervisor');
    if (relInput.length >= 1) {
      $('#supervisor').attr('rel', 0);
    }
  });

  $('#OrderType').on('change', function() {
    var selected = $(this)
      .find('option:selected')
      .text();
    if (selected == 'Presentation') {
      $('.display-time, .display-slide').show();
      $('.display-words').hide();
    } else {
      $('.display-time, .display-slide').hide();
      $('.display-words').show();
    }
  });

  $('#Status').on('change', function() {
    var selected = $(this)
      .find('option:selected')
      .text();
    if (selected == 'Requirements Incompleted') {
      $('.display-ExpectedDate').show();
    } else {
      $('.display-ExpectedDate').hide();
    }
  });
});

// external modal

function fieldSupervisorSort(where) {
  if (!where) {
    // All Table
    var badges = $('table.nislo-table-scroll td.fieldSupervisor');
  } else {
    var badges = where;
  }

  badges.each(function(index) {
    var badgeNum = $(this)
      .find('div.badge-supervisor')
      .not('div.d-none').length;
    console.log(badgeNum);

    if (badgeNum > 3) {
      $(this)
        .find('div.badge-supervisor')
        .addClass('sort');

      $(
        $(this)
          .find('div.badge-supervisor')
          .not($('div.badge-supervisor.own'))
          .get()
          .reverse()
      ).each(function(index) {
        index = index + 1;
        if (index <= 3) {
          $(this).css('z-index', index);
        } else {
          $(this).css('display', 'none');
        }
      });
    } else {
      $(this)
        .find('div.badge-supervisor')
        .removeClass('sort')
        .removeAttr('style');
    }
  });
}
